import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMagnifyingGlass,
  faUser,
  faUsers,
  faBars,
  faUserCircle,
  faSync,
  faRightFromBracket,
  faInfoCircle,
  faExternalLink,
  faTimes,
  faChevronRight,
  faQuestionCircle,
  faFilter,
  faChevronsLeft,
  faChevronLeft,
  faChevronsRight,
  faCopy,
  faMinus,
  faPlus,
  faAlignJustify,
  faLineColumns,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faPrint,
  faDownload,
  faShareNodes,
  faEnvelope,
  faTextSize,
  faExpand,
  faArrowUpFromBracket,
  faFilePdf,
  faFileMusic,
  faFileLines,
  faSpinnerThird,
  faPlay,
  faCompress,
  faBookBible,
  faChurch,
  faHandsHolding,
  faExclamation,
  faCircleInfo,
  faCircleExclamation,
  faExclamationTriangle,
  faTriangleExclamation,
  faArrowRight,
  faUndo,
  faRedo,
  faArrowLeft,
  faFileSearch,
  faImage,
  faThumbTack,
  faBackwardStep,
  faForwardStep,
  faGear,
  faPaintRoller,
  faCircle,
  faUserPlus,
  faClockRotateLeft,
  faHome,
  faHeart,
  faGrid2,
  faCheck,
  faMoonStars,
  faBrightness,
  faFolder,
  faMinusCircle,
  faAlbumCollectionCirclePlus,
  faTrash,
  faShuffle,
  faAlbumCollection,
  faSquareDashedCirclePlus,
  faGripDots,
  faMusic,
  faPencil,
  faRightToBracket,
  faFont,
  faHighlighter,
  faEraser,
  faGlobe,
  faStars,
  faRotateLeft,
  faRightFromLine,
  faVolume,
  faVolumeSlash,
  faMaximize,
  faWandMagicSparkles,
} from "@fortawesome/pro-light-svg-icons";

export function initFontAwesomeLight() {
  library.add(
    faAlignCenter,
    faAlignJustify,
    faAlignLeft,
    faAlignRight,
    faArrowRight,
    faArrowLeft,
    faArrowUpFromBracket,
    faBars,
    faBookBible,
    faBackwardStep,
    faForwardStep,
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
    faChurch,
    faCircle,
    faCircleExclamation,
    faCircleInfo,
    faCompress,
    faCopy,
    faCheck,
    faDownload,
    faEnvelope,
    faExclamation,
    faExclamationTriangle,
    faExpand,
    faGear,
    faExternalLink,
    faFileLines,
    faFilePdf,
    faFileSearch,
    faFilter,
    faHandsHolding,
    faInfoCircle,
    faImage,
    faLineColumns,
    faMagnifyingGlass,
    faMinus,
    faPlay,
    faHome,
    faHeart,
    faPlus,
    faPrint,
    faGrid2,
    faQuestionCircle,
    faRedo,
    faRightFromBracket,
    faShareNodes,
    faSpinnerThird,
    faSync,
    faTextSize,
    faTimes,
    faTriangleExclamation,
    faThumbTack,
    faClockRotateLeft,
    faUndo,
    faUser,
    faUsers,
    faUserPlus,
    faUserCircle,
    faPaintRoller,
    faMoonStars,
    faBrightness,
    faFolder,
    faMinusCircle,
    faAlbumCollectionCirclePlus,
    faTrash,
    faShuffle,
    faAlbumCollection,
    faSquareDashedCirclePlus,
    faGripDots,
    faMusic,
    faPencil,
    faRightToBracket,
    faFont,
    faHighlighter,
    faFileMusic,
    faEraser,
    faGlobe,
    faStars,
    faRotateLeft,
    faRightFromLine,
    faVolume,
    faVolumeSlash,
    faMaximize,
    faWandMagicSparkles,
  );
}
