import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAward,
  faExpand,
  faCompress,
  faFilePdf,
  faTriangleExclamation,
  faFileLines,
  faCircleExclamation,
  faCircleQuestion,
  faMoonStars,
  faLanguage,
  faRightToBracket,
  faStars,
} from "@fortawesome/pro-thin-svg-icons";

export function initFontAwesomeThin() {
  library.add(
    faAward,
    faExpand,
    faCompress,
    faFilePdf,
    faTriangleExclamation,
    faFileLines,
    faCircleExclamation,
    faCircleQuestion,
    faMoonStars,
    faLanguage,
    faRightToBracket,
    faStars,
  );
}
