<script setup lang="ts">
const store = useToastStore();
</script>

<template>
  <div
    v-if="store.toasts.length"
    id="toastPlacement"
    class="toast toast-center"
  >
    <Alert
      v-for="t of store.toasts"
      :key="t.id"
      :variant="t.variant"
      :closable="t.closable"
      :config="t"
      @close="store.removeToast(t.id)"
      >{{ t.message }}</Alert
    >
  </div>
</template>

<style scoped lang="postcss">
.toast {
  z-index: 200;
  @apply max-w-xl w-full;
}
</style>
