import { CCLIServerResponse } from "@/models/frontend-only/CCLIServerResponse";
import { CustomError } from "@/models/frontend-only/CustomError";

export interface ApiRequestOptions {
  method?: "PUT" | "POST" | "GET" | "DELETE" | "PATCH";
  disableHeaders?: boolean;
  disableToasts?: boolean;
  abort?: AbortController;
  headers?: { [key: string]: string };
}

export const useApiStore = defineStore("api", () => {
  const debug = useDebug();
  const { hideBusy } = useBusyStore();
  const translate = useTranslationStore();
  const { errorToast } = useToastStore();
  const { t } = useI18n();
  const profileStore = useProfileStore();

  function checkIfVersionIsOutdatedInResponseHeader(headers: any) {
    if (!headers) {
      return;
    }
    const currentAppBuildVersion = import.meta.env.VITE_BUILD_VERSION;

    if (currentAppBuildVersion === "REPLACE_WITH_VERSION") {
      return;
    }

    const serverBuildNumber =
      headers["SongSelect-Build-Id"] || headers["songselect-build-id"];

    debug.log(
      "Current Build Check",
      headers,
      serverBuildNumber,
      currentAppBuildVersion,
    );

    if (!serverBuildNumber) {
      return;
    }

    if (serverBuildNumber !== currentAppBuildVersion) {
      debug.log(
        "Builds Don't Match",
        serverBuildNumber,
        currentAppBuildVersion,
      );
      debug.log(serverBuildNumber, currentAppBuildVersion);
      document.location.reload();
    }
  }

  async function requestWrapper<T>(
    requestUrl: string,
    params: { [key: string]: any },
    config: ApiRequestOptions,
  ) {
    let body: string | FormData = params as unknown as string;
    let url = "/api" + requestUrl;
    let headers: { [key: string]: string } = config.headers || {};

    headers = {
      "client-locale": translate.currentLanguageCode,
      ...headers,
    };

    if (config.method === "POST") {
      headers = {
        RequestVerificationToken: profileStore.profile?.antiForgeryToken || "",
        ...headers,
      };
    }

    if (config.method === "POST" && params) {
      const formData = new FormData();
      Object.keys(params).forEach((k) => {
        if (Array.isArray(params![k])) {
          params![k].forEach((val: any) => {
            if (val !== null) {
              formData.append(k + "[]", val);
            }
          });
        } else if (params![k] !== null) {
          formData.append(k, params![k]);
        }
      });
      body = formData;
    }

    if (config.method === "GET" && params && Object.keys(params).length) {
      const searchParams = new URLSearchParams();
      Object.keys(params).forEach((k) => {
        if (Array.isArray(params![k])) {
          params![k].forEach((val: any) => {
            if (val !== null) {
              searchParams.append(k + "[]", val);
            }
          });
        } else if (params![k] !== null) {
          searchParams.append(k, params![k]);
        }
      });
      url += "?" + searchParams.toString();
    }

    const fetchOptions = {
      method: config.method,
      headers: headers,
      body: (config.method !== "GET" && body) || undefined,
      signal: config.abort?.signal || undefined,
    };

    debug.log("request-wrapper", url, config, fetchOptions);
    const response = await fetch(url, fetchOptions);
    return response;
  }

  async function request(
    url: string,
    options: ApiRequestOptions = {},
    params: { [key: string]: any } = {},
    secondAttempt?: boolean,
  ) {
    const { disableToasts = false } = options;
    const response: Response | null = await requestWrapper<CCLIServerResponse>(
      url,
      params,
      options,
    );
    checkIfVersionIsOutdatedInResponseHeader(response.headers);
    if (!response.ok) {
      await handleServerError(response, url, options);
      if (!secondAttempt) {
        return request(url, options, params, true);
      } else {
        errorToast(t("SV.MES_ErrorProcessingRequest").toString());
        throw new CustomError(
          { response, url: url },
          false,
          `API Request Error - 2nd try still failed (${response.status}): ${url}`,
        );
      }
    }
    return handleResponse(url, params, response, disableToasts);
  }

  async function handleServerError(
    err: Response,
    url: string,
    options: ApiRequestOptions = {},
  ) {
    const { disableToasts = false } = options;

    hideBusy();
    debug.log("API Response FAILED - handle server error", url, err);

    if (!err.status) {
      if (
        err.statusText.toLowerCase().includes("request aborted") ||
        err.statusText === "ERR_CANCELED"
      ) {
        throw err;
      }
      if (!disableToasts) {
        errorToast(t("SV.MES_ErrorProcessingRequest").toString());
      }
      if (
        err.statusText.toLowerCase().includes("network error") ||
        err.statusText.toLowerCase().includes("timeout exceeded")
      ) {
        throw new CustomError(
          { err, url: url },
          true,
          `Status Missing: ${url}`,
        );
      } else {
        throw new CustomError(
          { err, url: url },
          false,
          `Status Missing: ${url}`,
        );
      }
    }

    switch (err.status) {
      //unauthenticated - get updated auth status
      case 401:
        profileStore.getProfile();
        errorToast(t("SV.MES_SignedOut").toString());
        break;

      //conflict - antiforgery token is out of date
      case 409:
        profileStore.getProfile();
        break;

      default:
        if (!disableToasts) {
          errorToast(t("SV.MES_ErrorProcessingRequest").toString());
        }

        throw new CustomError(
          { err, url, options },
          false,
          `${url} - ${err.status}`,
        );
    }
  }

  async function handleResponse(
    url: string,
    requestParams: any,
    response: Response,
    disableToasts: boolean,
  ) {
    if (response.status !== 204) {
      let data: any;
      const clone = response.clone();
      try {
        data = await response.json();
      } catch {
        data = await clone.text();
      }

      if (data?.isSuccess === false) {
        hideBusy();
        if (data.publicStatusMessages && data.publicStatusMessages.length) {
          if (!disableToasts) {
            data.publicStatusMessages.forEach((m: string) => errorToast(m));
          }
        } else {
          if (!disableToasts) {
            errorToast(t("SV.MES_ErrorProcessingRequest").toString());
          }
          throw new Error("Missing Public Status Messages");
        }

        debug.log("API Response FAILED - handle response", url);
        debug.log(response);
        throw response;
      } else if (!response) {
        hideBusy();
        debug.log("API Response", url, requestParams, null);
        return null;
      }

      const payload = data.payload;
      debug.log("API Response", url, requestParams, data, payload);
      return payload;
    } else {
      return {};
    }
  }

  function get(
    url: string,
    params?: { [key: string]: any },
    options?: ApiRequestOptions,
  ) {
    const _options = Object.assign({ method: "GET" }, options || {});
    return request(url, _options, params);
  }

  function post(
    url: string,
    params?: { [key: string]: any },
    options?: ApiRequestOptions,
  ) {
    const _options = Object.assign({ method: "POST" }, options || {});
    return request(url, _options, params);
  }
  function del(
    url: string,
    params?: { [key: string]: any },
    options?: ApiRequestOptions,
  ) {
    const _options = Object.assign({ method: "DELETE" }, options || {});
    return request(url, _options, params);
  }

  return {
    request,
    get,
    post,
    del,
  };
});
