export function getImageSources(
  src: string,
  width: number,
  height: number,
  contain: boolean,
): { jpeg: string; webp: string; avif: string; orig: string } {
  let source = src;

  if (!source.match(/^http/)) {
    return {
      jpeg: "",
      webp: "",
      avif: "",
      orig: source,
    };
  }
  if (source.match(/.svg$/)) {
    return {
      jpeg: "",
      webp: "",
      avif: "",
      orig: source,
    };
  }

  if (source.includes("ccli-cdn.s3.amazonaws.com")) {
    source = source.replace(
      "https://ccli-cdn.s3.amazonaws.com",
      "https://cdn.ccli.com",
    );
  }

  if (source.includes("mzstatic.com")) {
    source = source.replace(/\/[^/]*$/, `/${width}x${height}bb.jpg`);
  }

  if (width === 0) {
    return {
      jpeg: "",
      webp: "",
      avif: "",
      orig: source,
    };
  }

  if (source.toLowerCase().match(/\.svg$/)) {
    return {
      jpeg: "",
      webp: "",
      avif: "",
      orig: source + `?format=auto&width=${width}`,
    };
  }

  if (import.meta.env.VITE_ENABLE_AZURE_IMAGE_PROXY === "1") {
    return {
      jpeg: getSourcePerImage(source, "jpg", width, height, contain),
      webp: getSourcePerImage(source, "webp", width, height, contain),
      avif: getSourcePerImage(source, "avif", width, height, contain),
      orig: source,
    };
  } else {
    if (
      source.includes("images.ctfassets.net") &&
      (source.includes(".jpg") || source.includes(".png"))
    ) {
      source + `?fm=webp&w=${width}`;
      return {
        jpeg: source + `?fm=jpg&w=${width}`,
        webp: source + `?fm=webp&w=${width}`,
        avif: source + `?fm=avif&w=${width}`,
        orig: source + ``,
      };
    }

    if (source.includes("mzstatic.com")) {
      return {
        jpeg: source.replace(/\/[^/]*$/, `/${width}x${height}bb.jpg`),
        webp: source.replace(/\/[^/]*$/, `/${width}x${height}bb.webp`),
        avif: "",
        orig: source,
      };
    }
    return {
      jpeg: "",
      webp: "",
      avif: "",
      orig: source,
    };
  }
}

function getSourcePerImage(
  rawImageSource: string,
  format: "avif" | "jpg" | "webp",
  width?: number,
  height?: number,
  contain?: boolean,
) {
  const source = rawImageSource;

  const searchParams: { [key: string]: string } = {
    url: source,
    format: format,
    fit: contain ? "contain" : "cover",
  };

  if (width) {
    searchParams.width = width.toString();
  }
  if (height) {
    searchParams.height = height.toString();
  }

  const imgProxyParams = new URLSearchParams(searchParams);

  return `${
    import.meta.env.VITE_IMAGE_PROXY_URL
  }/api/GetOptimizedImage?${imgProxyParams.toString()}`;
}
