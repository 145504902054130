import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAdd,
  faCirclePause,
  faExpandAlt,
  faCartShopping,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleExclamation,
  faCirclePlay,
  faExclamationTriangle,
  faFileMusic,
  faHeart,
  faHome,
  faFilm,
  faMusic,
  faPause,
  faPlay,
  faTimes,
  faTriangleExclamation,
  faSpinnerThird,
  faUser,
  faUsers,
  faUserPlus,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faClockRotateLeft,
  faCaretUp,
  faRepeat,
  faGear,
  faFont,
  faCog,
  faBackwardStep,
  faRightFromLine,
  faVolume,
  faVolumeSlash,
  faMaximize,
  faInfoCircle,
  faMountains,
  faStars,
  faArrowTrendUp,
} from "@fortawesome/pro-solid-svg-icons";

export function initFontAwesomeSolid() {
  library.add(
    faSpinnerThird,
    faAdd,
    faHeart,
    faHome,
    faCircle,
    faPause,
    faPlay,
    faFilm,
    faUser,
    faUsers,
    faExpandAlt,
    faCartShopping,
    faCaretDown,
    faCaretUp,
    faMusic,
    faCirclePlay,
    faUserPlus,
    faCheck,
    faTimes,
    faClockRotateLeft,
    faGear,
    faCheckCircle,
    faCirclePause,
    faExclamationTriangle,
    faTriangleExclamation,
    faChevronLeft,
    faChevronRight,
    faCircleExclamation,
    faFileMusic,
    faRepeat,
    faFont,
    faCog,
    faBackwardStep,
    faRightFromLine,
    faVolume,
    faVolumeSlash,
    faMaximize,
    faInfoCircle,
    faMountains,
    faStars,
    faArrowTrendUp,
  );
}
