import supportedBrowsers from "./../supportedBrowsers";

export const useAppStore = defineStore("app", () => {
  const showFooter = ref(false);
  const productionMode = import.meta.env.MODE === "production";
  const isMobileApp = import.meta.env.MODE === "cap";
  const enableNewSSMessaging = import.meta.env.VITE_ENABLE_NEW_SS_MSG === "1";

  function updateTitleAndDescription(data: {
    title: string;
    description: string;
  }) {
    useHead({
      title: data.title,
      meta: [
        {
          name: "description",
          content: data.description,
        },
      ],
    });
  }

  function updateDescription(description: string) {
    useHead({
      meta: [
        {
          name: "description",
          content: description,
        },
      ],
    });
  }

  function updateTitle(title: string) {
    useHead({
      title: title,
    });
  }

  // const isImageWebPSupported = (() => {
  //   const elem = document.createElement("canvas");
  //   let supported: boolean = false;
  //   if (!!(elem.getContext && elem.getContext("2d"))) {
  //     supported = elem.toDataURL("image/webp").indexOf("data:image/webp") == 0;
  //   } else {
  //     // very old browser like IE 8, canvas not supported
  //     supported = false;
  //   }
  //   return supported;
  // })();

  const browserSupported = supportedBrowsers.test(navigator.userAgent);

  function checkBrowserSupport() {
    if (!isMobileApp && !browserSupported) {
      const modals = useModalStore();
      modals.showModal("UnsupportedBrowserModal");
    }
  }

  return {
    checkBrowserSupport,
    browserSupported,
    productionMode,
    enableNewSSMessaging,
    updateTitleAndDescription,
    updateTitle,
    updateDescription,
    isMobileApp,
    showFooter,
  };
});
