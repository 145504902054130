<script setup lang="ts">
import { LoadStatus } from "@/models/frontend-only/LoadStatus";

const props = defineProps<{
  src: string;
  alt: string;
  rounded?: boolean;
  fetchPriority?: string;
  transparent?: boolean;
  contain?: boolean;
  noLazy?: boolean;
  noTransition?: boolean;
  placeholder?: boolean;
  placeholderIcon?: string;
  noPlaceholderIcon?: boolean;
}>();

const div = ref<HTMLDivElement>();
const { pixelRatio } = useDevicePixelRatio();
const { width: elementWidth, height: elementHeight } = useElementSize(div);
const requestWidth = computed(() =>
  Math.ceil(elementWidth.value * pixelRatio.value),
);
const requestHeight = computed(() =>
  Math.ceil(elementHeight.value * pixelRatio.value),
);

const debouncedFn = useDebounceFn(getSource, 50);
const alteredSrc = ref<{
  jpeg: string;
  webp: string;
  avif: string;
  orig: string;
}>({ jpeg: "", webp: "", avif: "", orig: "" });
watch(elementWidth, debouncedFn);

onMounted(getSource);
watch(() => props.src, getSource, { deep: true });

function getSource() {
  alteredSrc.value = getImageSources(
    props.src,
    requestWidth.value,
    requestHeight.value,
    props.contain || false,
  );
}

const fallbackImg = ref(false);
const status = ref<LoadStatus>(LoadStatus.Loading);

const emits = defineEmits<{
  (e: "load", evt: Event): void;
  (e: "error", evt: Event): void;
}>();

function onLoad(event: Event) {
  emits("load", event);
  status.value = LoadStatus.Ready;
}
function onError(event: Event, fallbackImgFail?: boolean) {
  if (!fallbackImgFail) {
    fallbackImg.value = true;
  } else {
    status.value = LoadStatus.Error;
    emits("error", event);
  }
}
</script>

<template>
  <div
    ref="div"
    class="image-wrapper"
    :class="{
      rounded: rounded,
      transparent: transparent,
    }"
  >
    <FAIcon
      v-if="status === LoadStatus.Error && !placeholder && !noPlaceholderIcon"
      :class="placeholderIcon || 'fa-light fa-image'"
    />

    <picture v-if="!placeholder && !fallbackImg">
      <source
        v-if="alteredSrc.avif"
        :srcset="alteredSrc.avif"
        type="image/avif"
      />
      <source
        v-if="alteredSrc.webp"
        :srcset="alteredSrc.webp"
        type="image/webp"
      />
      <source
        v-if="alteredSrc.jpeg"
        :srcset="alteredSrc.jpeg"
        type="image/jpeg"
      />
      <img
        :src="alteredSrc.orig"
        :fetchpriority="fetchPriority"
        class="image"
        :class="{
          'image-loading': status === LoadStatus.Loading,
          'image-error': status === LoadStatus.Error,
          'image-ready': status === LoadStatus.Ready,
          'no-transition': noTransition,
          contain: contain,
        }"
        :alt="alt"
        :loading="(!noLazy && 'lazy') || undefined"
        @load="onLoad"
        @error="onError"
      />
    </picture>
    <img
      v-else-if="fallbackImg"
      :src="src"
      :fetchpriority="fetchPriority"
      class="image image-ready"
      :class="{
        'no-transition': noTransition,
        'image-loading': status === LoadStatus.Loading,
        'image-error': status === LoadStatus.Error,
        'image-ready': status === LoadStatus.Ready,
        contain: contain,
      }"
      :alt="alt"
      :loading="(!noLazy && 'lazy') || undefined"
      @load="onLoad"
      @error="(e) => onError(e, true)"
    />

    <span
      v-else
      class="placeholder"
    />
  </div>
</template>

<style scoped lang="postcss">
i,
svg {
  @apply opacity-25;
}
.image-wrapper {
  @apply flex h-full w-full items-center justify-center;
  &.rounded {
    @apply overflow-hidden rounded-md;
  }
  &:not(.transparent) {
    @apply bg-gray-100;
  }

  .image-loading {
    opacity: 0;
  }
  .image-error {
    opacity: 0;
    display: none;
  }
  .image-ready {
    opacity: 1;
  }
}
picture,
img {
  @apply h-full w-full  object-cover object-center;

  &.contain {
    @apply object-contain;
  }
  &:not(.no-transition) {
    transition: 0.2s all ease;
  }
}
.placeholder {
  @apply block h-full w-full;
}
</style>
