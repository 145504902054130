<script setup lang="ts">
const props = defineProps<{
  class: string;
}>();

const kitIcon = computed(() => props.class.includes("fa-kit") || false);
const faIcon = computed(() =>
  props.class
    .split(" ")
    .filter((c) => c)
    .filter((c) => c.match(/^fa-/))
    .filter(
      (c) =>
        ![
          "fa-light",
          "fa-regular",
          "fa-thin",
          "fa-kit",
          "fa-solid",
          "fa-spin",
          "fa-fw",
          "fa-2x",
          "fa-3x",
          "fa-4x",
          "fa-5x",
        ].includes(c),
    ),
);
const faLibrary = computed(() =>
  props.class
    .split(" ")
    .find((c) =>
      ["fa-light", "fa-regular", "fa-thin", "fa-kit", "fa-solid"].includes(c),
    ),
);

const classes = computed(() =>
  props.class.split(" ").filter((c) => !c.match(/^fa-/)),
);

const fixedWidth = computed(
  () => !!props.class.split(" ").find((c) => c === "fa-fw"),
);

const spin = computed(
  () => !!props.class.split(" ").find((c) => c === "fa-spin"),
);

const size = computed(
  () =>
    props.class
      .split(" ")
      .find((c) => ["fa-2x", "fa-3x", "fa-4x", "fa-5x"].includes(c))
      ?.split("-")[1] || undefined,
);
</script>

<template>
  <font-awesome-icon
    v-if="!kitIcon"
    :fixed-width="fixedWidth"
    :spin="spin"
    :icon="faLibrary + ' ' + faIcon"
    :size="size"
    :class="classes"
  />
  <i
    v-else
    :class="class"
  />
</template>

<style scoped lang="postcss"></style>
