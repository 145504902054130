<script setup lang="ts">
const fav = useFavStore();
const profile = useProfileStore();
const modals = useModalStore();
const app = useAppStore();

const SelectEmailModal = defineAsyncComponent(
  () => import("@/components/Modals/SelectEmailModal.vue"),
);

const ConfirmDeleteFavoritesListModal = defineAsyncComponent(
  () => import("@/components/Modals/ConfirmDeleteFavoritesListModal.vue"),
);
const WelcomeModal = defineAsyncComponent(
  () => import("@/components/Modals/WelcomeModal.vue"),
);
const SignInModal = defineAsyncComponent(
  () => import("@/components/Modals/SignInModal.vue"),
);
const UpgradeRequiredModal = defineAsyncComponent(
  () => import("@/components/Modals/UpgradeRequiredModal.vue"),
);
const SSRequiredModal = defineAsyncComponent(
  () => import("@/components/Modals/SSRequiredModal.vue"),
);
const SubscriberFeatureModal = defineAsyncComponent(
  () => import("@/components/Modals/SubscriberFeatureModal.vue"),
);
const UnsupportedBrowserModal = defineAsyncComponent(
  () => import("@/components/Modals/UnsupportedBrowserModal.vue"),
);
const ShareModal = defineAsyncComponent(
  () => import("@/components/Modals/ShareModal.vue"),
);
const PreferenceModal = defineAsyncComponent(
  () => import("@/components/Modals/PreferenceModal.vue"),
);

const InviteUserModal = defineAsyncComponent(
  () => import("@/components/Modals/InviteUserModal.vue"),
);
const ChordProInfoModal = defineAsyncComponent(
  () => import("@/components/Modals/ChordProInfoModal.vue"),
);

const AddSongModal = defineAsyncComponent(
  () => import("@/components/Modals/AddSongModal.vue"),
);
const MaxSongModal = defineAsyncComponent(
  () => import("@/components/Modals/MaxSongModal.vue"),
);
// const MediaPlayerSettingsModal = defineAsyncComponent(
//   () => import("@/components/Modals/MediaPlayerSettingsModal.vue"),
// );
const NavGuardModal = defineAsyncComponent(
  () => import("@/components/Modals/NavGuardModal.vue"),
);
const NewListModal = defineAsyncComponent(
  () => import("@/components/Modals/NewListModal.vue"),
);
const RenameListModal = defineAsyncComponent(
  () => import("@/components/Modals/RenameListModal.vue"),
);
const SelectListModal = defineAsyncComponent(
  () => import("@/components/Modals/SelectListModal.vue"),
);
const SelectProfileModal = defineAsyncComponent(
  () => import("@/components/Modals/SelectProfileModal.vue"),
);
const UniqueSongsAboutModal = defineAsyncComponent(
  () => import("@/components/Modals/UniqueSongsAboutModal.vue"),
);
const AddFilterDropdownModal = defineAsyncComponent(
  () => import("@/components/Modals/AddFilterDropdownModal.vue"),
);
const ConfirmResetSettingsModal = defineAsyncComponent(
  () => import("@/components/Modals/ConfirmResetSettingsModal.vue"),
);

const ConfirmClearStylesModal = defineAsyncComponent(
  () => import("@/components/Modals/ConfirmClearStylesModal.vue"),
);

const NewFeaturesModal = defineAsyncComponent(
  () => import("@/components/Modals/NewFeaturesModal.vue"),
);
</script>

<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-if="modals.showBusy"
        class="fixed bottom-0 left-0 right-0 top-0 z-[1000] flex items-center justify-center bg-black/15"
      >
        <div
          class="flex aspect-square items-center justify-center rounded-full bg-white/50 p-3 text-xl leading-4 backdrop-blur"
        >
          <FAIcon
            class="fa-spin fa-light fa-spinner-third fa-fw inline-block"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
  <div>
    <WelcomeModal
      v-if="app.enableNewSSMessaging && modals.enabledModal === 'WelcomeModal'"
    />
    <SignInModal v-if="modals.enabledModal === 'SignInModal'" />
    <UpgradeRequiredModal
      v-if="modals.enabledModal === 'UpgradeRequiredModal'"
    />
    <SSRequiredModal v-if="modals.enabledModal === 'SSRequiredModal'" />
    <SubscriberFeatureModal
      v-if="modals.enabledModal === 'SubscriberFeatureModal'"
    />
    <UnsupportedBrowserModal
      v-if="
        modals.enabledModal === 'UnsupportedBrowserModal' && !app.isMobileApp
      "
    />
    <ShareModal v-if="modals.enabledModal === 'ShareModal'" />
    <PreferenceModal v-if="modals.enabledModal === 'PreferenceModal'" />
    <SelectListModal
      v-if="
        modals.enabledModal === 'SelectListModal' &&
        fav.enableSharedFavorites &&
        profile.profile?.isAdmin
      "
    />
    <InviteUserModal
      v-if="
        profile.enableInviteUser && modals.enabledModal === 'InviteUserModal'
      "
    />
    <ChordProInfoModal v-if="modals.enabledModal === 'ChordProInfoModal'" />
    <AddSongModal
      v-if="profile.profile?.isAdmin && modals.enabledModal === 'AddSongModal'"
    />
    <MaxSongModal v-if="modals.enabledModal === 'MaxSongModal'" />

    <NavGuardModal v-if="modals.enabledModal === 'NavGuardModal'" />
    <NewListModal
      v-if="profile.profile?.isAdmin && modals.enabledModal === 'NewListModal'"
    />
    <RenameListModal
      v-if="
        profile.profile?.isAdmin && modals.enabledModal === 'RenameListModal'
      "
    />
    <SelectListModal v-if="modals.enabledModal === 'SelectListModal'" />
    <SelectProfileModal v-if="modals.enabledModal === 'SelectProfileModal'" />
    <UniqueSongsAboutModal
      v-if="modals.enabledModal === 'UniqueSongsAboutModal'"
    />
    <ConfirmDeleteFavoritesListModal
      v-if="
        profile.profile?.isAdmin &&
        modals.enabledModal === 'ConfirmDeleteFavoritesListModal'
      "
    />
    <AddFilterDropdownModal
      v-if="modals.enabledModal === 'AddFilterDropdownModal'"
    />
    <SelectEmailModal v-if="modals.enabledModal === 'SelectEmailModal'" />
    <ConfirmClearStylesModal
      v-if="modals.enabledModal === 'ConfirmClearStylesModal'"
    />
    <ConfirmResetSettingsModal
      v-if="modals.enabledModal === 'ConfirmResetSettingsModal'"
    />
    <NewFeaturesModal v-if="modals.enabledModal === 'NewFeaturesModal'" />
    <!-- <MediaPlayerSettingsModal
      v-if="modals.enabledModal === 'MediaPlayerSettingsModal'"
    /> -->
  </div>
</template>

<style scoped lang="postcss"></style>
